import { Injectable } from '@angular/core';
import {CLPrint, Repository, Structures} from "@clavisco/core";
import {Router} from "@angular/router";
import {IToken} from "../interfaces/i-token";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public currentUserSubject: BehaviorSubject<any>;
  public currentPermissionsSubject!: BehaviorSubject<any>;

  constructor(private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(Repository.Behavior.GetStorageObject('currentUser'));
    this.currentPermissionsSubject = new BehaviorSubject<any>(Repository.Behavior.GetStorageObject('permissions'));
  }

  public logout():void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('permissions');
    this.currentUserSubject.next(null);
    this.currentPermissionsSubject.next(null);
    this.router.navigateByUrl('/login');
  }

}
