import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-modal-error-info',
  templateUrl: './modal-error-info.component.html',
  styleUrls: ['./modal-error-info.component.scss']
})
export class ModalErrorInfoComponent implements OnInit {

  CreatedDate: Date = new Date()
  ErrorMessage: string = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
  ) { }

  ngOnInit(): void {
    this.Onload();
  }

  Onload(): void {
    this.CreatedDate = this.data.CreatedDate;
    this.ErrorMessage = this.data.ErrorMessage;
  }

  CloseModal(): void {
    this.dialogRef.close();
  }

}
