import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_ROUTING } from './app.routing';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import { PagesComponent } from './pages/pages.component';
import {LoginComponent} from "./pages/login/login.component";
import {LinkerModule, LinkerService} from "@clavisco/linker";
import {OverlayModule, OverlayService} from "@clavisco/overlay";
import {AlertsModule, AlertsService} from "@clavisco/alerts";
import {ReactiveFormsModule} from "@angular/forms";
import {DatePipe, NgOptimizedImage} from "@angular/common";
import {LoginModule} from "@clavisco/login";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {Miscellaneous} from "@clavisco/core";
import {AuthGuard} from "./core/guards/auth.guard";
import {AuthenticationService} from "./core/services/authentication.service";
import {ErrorInterceptor} from "./theme/utils/error.interceptor";
import {UrlInterceptor} from "./theme/utils/url.interceptor";
import {AppSettings} from "./app.settings";
import {MenuComponent} from "./theme/components/menu/menu.component";
import {TokenInterceptor} from "./theme/utils/token.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PagesComponent,
    MenuComponent,
  ],
  imports: [
    BrowserModule,
    APP_ROUTING,
    SharedModule,
    LinkerModule,
    OverlayModule,
    AlertsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    LoginModule
  ],
  providers: [
    AppSettings,
    AuthGuard,
    AuthenticationService,
    {
      provide: 'LinkerService',
      useClass: LinkerService
    },
    {
      provide: 'AlertsService',
      useClass: AlertsService
    },
    {
      provide: 'OverlayService',
      useClass: OverlayService
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LdjSSsqAAAAAEwjXp07Zix1WxdgxgDGnvi889Io'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.PagedRequestInterceptor,
      multi: true
    },
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },{
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
