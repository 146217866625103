import { Injectable } from '@angular/core';

export class Settings {
  constructor(
    public name: string,
    public theme: string,
    public rtl: boolean
  ) { }
}

@Injectable()
export class AppSettings {
  public settings = new Settings(
    'FE Colombia',  // theme name
    'clavis',     // green, blue, red, pink, purple, grey
    false
  )
}
