import { Injectable } from '@angular/core';
import {StorageService} from "./storage.service";
import {IMenuNode} from "@clavisco/menu";
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GeneralConfigsService {

  private dataUpdatedSource: Subject<void> = new Subject<void>();
  private NameAction: BehaviorSubject<string> = new BehaviorSubject<string>('');
  Name: Observable<string> = this.NameAction.asObservable();

  constructor(
    private storageService: StorageService
  ) { }

  public CheckMenuPermissions(permissionList: string[], listMenu: IMenuNode[]): IMenuNode[] {
    const menuNodes: IMenuNode[] = [];
    // Se maneja el nombre del permiso igual al key del menú de clavis
    listMenu.filter((element) =>  permissionList?.findIndex(item => item===element.Key)>-1  ||
      element.Key == "home" || element.Key == "logout")
      .forEach((element) => {
        const filteredNodes = element.Nodes ? this.CheckMenuPermissions(permissionList, element.Nodes) : [];
        if (filteredNodes.length > 0) {
          element.Nodes = filteredNodes;
        }
        menuNodes.push(element);
      });
    return menuNodes;
  }

  public CheckPermission(permissionName: string): boolean {
    let permissionList: string[] = this.storageService.GetPerms() ;

    if (permissionList && permissionList.length) {
      return permissionList.some((permission) => permission === permissionName);
    } else {
      return false;
    }
  }

  //Establece el nombre de la ventana activa en la barra de menú.
  SetNameAction(Action:string):void{
    this.NameAction.next(Action);
  }
}
