import { Injectable } from '@angular/core';
import {Repository} from "@clavisco/core";
import {Token} from "@angular/compiler";
import {IPermissionsSelectedModel} from "../interfaces/i-permission-by-rol";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private localStorageService:any;
  constructor() {
    this.localStorageService = localStorage;
  }

  GetCurrentSession():Token | null{
    return Repository.Behavior.GetStorageObject("currentUser");
  }

  // metodo para obtener un item del almacenamiento local
  GetItem(itemName: string): string {
    const item = this.localStorageService.getItem(itemName);
    return item;
  }

  SetPerms(perms: IPermissionsSelectedModel[]):void {
    this.localStorageService.setItem('perms', JSON.stringify(perms));
  }

  GetPerms():string[] {
    return JSON.parse(this.localStorageService.getItem('perms')!);
  }

}
