<div class="modal-header">
  <h1 class="modal-title">Información</h1>
</div>
<div class="modal-body">
  <div class="row-container" fxLayout="column">
    <div class="row-item mt-1" fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.gt-sm="100" style="margin-bottom: 15px;">
      <label class="w-100">Fecha de Creación:</label>
    </div>
    <div class="row-item" fxflex="100" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.gt-sm="100" style="margin-bottom: 15px;">
      <label class="w-100">{{ CreatedDate | date:"dd/MM/yyyy" }}</label>
    </div>
    <div *ngIf="ErrorMessage != null && ErrorMessage !== ''" class="row-item" fxflex="100" fxFlex.xs="100" fxFlex.sm="100"
         fxFlex.md="100" fxFlex.gt-sm="100" style="margin-bottom: 15px;">
      <label class="w-100 mt-1">Detalle de error interno:</label>
    </div>
    <div class="row-item" *ngIf="ErrorMessage != null && ErrorMessage !== ''" fxflex="100" fxFlex.xs="100" fxFlex.sm="100"
         fxFlex.md="100" fxFlex.gt-sm="100" style="margin-bottom: 15px;">
      <label class="w-100 ">{{ ErrorMessage }}</label>
    </div>
<!--    <div class="row-item" *ngIf="ErrorRespHacienda != null && ErrorRespHacienda != ''" fxflex="100" fxFlex.xs="100"-->
<!--         fxFlex.sm="100" fxFlex.md="100" fxFlex.gt-sm="100">-->
<!--      <label class="w-100 mt-1">Detalle de error respuesta Hacienda:</label>-->
<!--    </div>-->
<!--    <div class="row-item" *ngIf="ErrorRespHacienda != null && ErrorRespHacienda != ''" fxflex="100" fxFlex.xs="100"-->
<!--         fxFlex.sm="100" fxFlex.md="100" fxFlex.gt-sm="100">-->
<!--      <label class="w-100">{{ ErrorRespHacienda }}</label>-->
<!--    </div>-->
  </div>
</div>

<div class="modal-footer">
  <div class="mt-2" fxLayout="row" fxLayoutAlign="end">
    <button type="button" mat-flat-button class="mt-btn" color="primary" (click)="CloseModal()">
      <mat-icon>close</mat-icon>
      <span class="material-icons-outlined">Cerrar</span>
    </button>
  </div>
</div>

