import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {Observable} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {UntypedFormGroup} from "@angular/forms";
import {IUser, IUserPaginator} from "../interfaces/i-user";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly CONTROLLER = 'Users';

  constructor( private http: HttpClient,
               private storage: StorageService) { }

  Get(StartPos: number, RowsReturned: number, Name: string, LastName: string, UserName: string,
      IsActive: boolean): Observable<ICLResponse<IUserPaginator[]>> {
    const params = new HttpParams()
      .set('startPos', StartPos)
      .set('rowsReturned', RowsReturned)
      .set('name', Name)
      .set('lastName', LastName)
      .set('userName', UserName)
      .set('isActive', IsActive);
    return this.http.get<ICLResponse<IUserPaginator[]>>(`${this.CONTROLLER}/Filter`, {params});
  }

  Post(userForm: UntypedFormGroup): Observable<Structures.Interfaces.ICLResponse<IUser>> {
    const _user: IUser = {
      Id: 0,
      CreatedDate: new Date(),
      CreatedBy: JSON.parse(atob(this.storage.GetItem('currentUser')!)).userName,
      UpdateDate: new Date(),
      UpdatedBy: ' ',
      IsActive: userForm.value.IsActive,
      Name: userForm.value.Name,
      LastName: userForm.value.LastName,
      UserName: userForm.value.UserName,
      Password: userForm.value.Password,
      RoleId: userForm.value.RoleId,
    };
    return this.http.post<Structures.Interfaces.ICLResponse<IUser>>(this.CONTROLLER, _user);
  }

  Patch(userForm: UntypedFormGroup): Observable<Structures.Interfaces.ICLResponse<IUser>> {
    const _user: IUser = {
      Id: userForm.value.Id,
      CreatedDate: new Date(),
      CreatedBy: '',
      UpdateDate: new Date(),
      UpdatedBy: JSON.parse(atob(this.storage.GetItem('currentUser')!)).userName,
      IsActive: userForm.value.IsActive,
      Name: userForm.value.Name,
      LastName: userForm.value.LastName,
      UserName: userForm.value.UserName,
      Password: userForm.value.Password !== '' ? userForm.value.Password : '',
      RoleId: userForm.value.RoleId,
    };
    return this.http.patch<Structures.Interfaces.ICLResponse<IUser>>(this.CONTROLLER, _user)
  }
}
