import {RouterModule, Routes} from "@angular/router";
import {PagesComponent} from "./pages/pages.component";
import {AuthGuard} from "./core/guards/auth.guard";
import {CheckPermissionsGuard} from "./core/guards/check-permissions.guard";

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'login', // Redirige la URL vacía a 'login'
    pathMatch: 'full' // Asegura una coincidencia completa de la URL
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  {
    path: '',
    component: PagesComponent, children: [
      { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canActivate:[AuthGuard]},
      { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), canActivate:[AuthGuard]},
      { path: 'permissions', loadChildren: () => import('./pages/perms/perm-by-rol/perm-by-rol.module').then(m => m.PermByRolModule), canActivate:[AuthGuard]},
      { path: 'roles', loadChildren: () => import('./pages/rol/rol.module').then(m => m.RolModule), canActivate:[AuthGuard]},
      { path: 'payrolls', loadChildren: () => import('./pages/payroll/payroll.module').then(m => m.PayrollModule), canActivate:[AuthGuard]},
    ]
  },
]

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES,{ useHash: true});
