import { Component, OnInit } from '@angular/core';
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import { LoginService } from "@clavisco/login";
import { CLPrint } from "@clavisco/core";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  callbacks: ICLCallbacksInterface<CL_CHANNEL> = { Callbacks: {}, Tracks: [], };
  ShouldResolve: boolean = true;
  LoginId: string = 'LoginId';
  ApiUrl: string = environment.ApiUrl;
  PathToRedirect: string = 'home';
  LogoPath: string = '/assets/logo-clavis.png';
  DotNetApiType : 'CORE' = 'CORE';
  SessionName: string = "currentUser";
  UseReCaptcha:boolean = true;
  EnforcePasswordPolicy: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
