<cl-menu [Id]="MenuId" [Logo]="Logo" [Title]="Title" [User]="currentUser" [Nodes]="MenuNodes">
  <div class="exclude" style="width: 100%">
    <mat-toolbar class="Base " fxLayout="row wrap" fxLayoutAlign="space-between center" >
      <div fxLayoutAlign="none center" fxLayoutGap="20px">
        <button mat-icon-button (click)="ToggleMenu()">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-label  class="toolbar-title" >{{txtaccion}}</mat-label>
      </div>
    </mat-toolbar>
    <router-outlet class="exclude"></router-outlet>
  </div>
</cl-menu>
