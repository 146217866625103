import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {IPermission} from "../interfaces/i-permission";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private readonly CONTROLLER = 'Permissions'

  constructor(private http: HttpClient) {
  }

  GetPermissionsByRole(roleId: number): Observable<ICLResponse<IPermission[]>> {
    const params = new HttpParams()
      .set('roleId', roleId);

    return this.http.get<ICLResponse<IPermission[]>>(`${this.CONTROLLER}/GetPermissionsByRole`, {params});
  }

  public GetPermissionsNoAssignedByRole(roleId: number): Observable<ICLResponse<IPermission[]>> {
    const params = new HttpParams()
      .set('roleId', roleId);

    return this.http.get<ICLResponse<IPermission[]>>(`${this.CONTROLLER}/GetPermissionsNoAssignedByRole`, {params});
  }
}

